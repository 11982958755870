@import './variables.scss';
@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700,400italic,700italic&subset=latin,latin-ext");

body {
    margin: 0;
    font-family:'Noto Sans', sans-serif;
}

.brand-error {
    background-color: #fce4e4;
    border: 1px solid #cc0033;
    outline: none;
}

.tox-statusbar__branding {
    display: none;
}

.tox.tox-tinymce {
    width: 100%;
}

.container-box{
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 10px;
    margin-bottom: 20px;

    &.form-box{
      padding: 20px 0;  
      position: relative;
    }
}

.search-row{
    display: flex;
    align-items: end;
    margin-bottom: 20px;
    flex-wrap: wrap;
    margin: 0 -5px;
    .search-box{
        display: flex;
        align-items: center;
        margin: 0 5px;
        margin-bottom: 20px;
    }
    .search-right-wrap{
        flex-grow: 1;
        text-align: right;
    }
    .filter-box{
        margin-left: auto;
        order: 2;
    }
}

.shadow-box{
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 5px;
    background-color: #fff;
}

.sm-note{
    display: block;
    font-size: 12px;
    color: #9e9e9e;
}

hr{
    border: 0;
    border-top: 1px solid #ccc;
}

.expand-wrap{
    display: flex;
    background-color: #72869B;
    padding: 4px 10px;
    margin-right: 15px;
    align-items: center;
    color: #fff;

    .title{
        margin-right: 15px;
    }
}

.right-main-container{
    width:calc(100% - 240px);
    margin-left: auto;
}

.main-logo{
    width: 100px;
    cursor: pointer;
}

@media only screen and (max-width: $md-view) {
    .right-main-container{
        width:100%;
    }
}

.paper-wrap{
    padding: 10px;
    margin-bottom: 15px;
}
.full-page-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}